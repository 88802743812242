import React from 'react'
import { css, SerializedStyles } from '@emotion/core'
import { MOBILE, TABLET_OR_LESS, DESKTOP_OR_LESS } from '../../../constants/theme.styles'

const spreaderBaseStyles = css`
  display: flex;
`

const verticalStyles = css`
  flex-direction: column;
`

// WRAPPING
interface IWrapStyles {
  all: SerializedStyles
  mobileOnly: SerializedStyles
  tabletOrLess: SerializedStyles
  desktopOrLess: SerializedStyles
}

const wrapStyles: IWrapStyles = {
  all: css`
    flex-wrap: wrap;
  `,
  mobileOnly: css`
    @media ${MOBILE} {
      flex-wrap: wrap;
    }
  `,
  tabletOrLess: css`
    @media ${TABLET_OR_LESS} {
      flex-wrap: wrap;
    }
  `,
  desktopOrLess: css`
    @media ${DESKTOP_OR_LESS} {
      flex-wrap: wrap;
    }
  `
}

// CONTAINER SPACING
interface ISpacingStyles {
  none: SerializedStyles
  veryTight: SerializedStyles
  superTight: SerializedStyles
  tight: SerializedStyles
  relaxed: SerializedStyles
  veryRelaxed: SerializedStyles
}

const superTightSpacing = '4px'
const veryTightSpacing = '0.8rem'
const tightSpacing = '1.6rem'
const relaxedSpacing = '3.04rem'
const veryRelaxedSpacing = '4.8rem'

const spacingStyles: ISpacingStyles = {
  none: css`
    margin-top: 0;
    margin-left: 0;
  `,
  superTight: css`
    margin-top: -${superTightSpacing};
    margin-left: -${superTightSpacing};
  `,
  veryTight: css`
    margin-top: -${veryTightSpacing};
    margin-left: -${veryTightSpacing};
  `,
  tight: css`
    margin-top: -${tightSpacing};
    margin-left: -${tightSpacing};
  `,
  relaxed: css`
    margin-top: -${relaxedSpacing};
    margin-left: -${relaxedSpacing};
  `,
  veryRelaxed: css`
    margin-top: -${veryRelaxedSpacing};
    margin-left: -${veryRelaxedSpacing};
  `
}

const itemSpacingStyles: ISpacingStyles = {
  none: css`
    margin-top: 0;
    margin-left: 0;
  `,
  superTight: css`
    margin-top: ${superTightSpacing};
    margin-left: ${superTightSpacing};
  `,
  veryTight: css`
    margin-top: ${veryTightSpacing};
    margin-left: ${veryTightSpacing};
  `,
  tight: css`
    margin-top: ${tightSpacing};
    margin-left: ${tightSpacing};
  `,
  relaxed: css`
    margin-top: ${relaxedSpacing};
    margin-left: ${relaxedSpacing};
  `,
  veryRelaxed: css`
    margin-top: ${veryRelaxedSpacing};
    margin-left: ${veryRelaxedSpacing};
  `
}

// ALIGNMENT
interface IAlignmentStyles {
  start: SerializedStyles
  end: SerializedStyles
  center: SerializedStyles
  stretch: SerializedStyles
  baseline: SerializedStyles
}

const alignmentStyles: IAlignmentStyles = {
  start: css`
    align-items: flex-start;
  `,
  end: css`
    align-items: flex-end;
  `,
  center: css`
    align-items: center;
  `,
  stretch: css`
    align-items: stretch;
  `,
  baseline: css`
    align-items: baseline;
  `
}

// DISTRIBUTION STYLES
interface IDistributionStyles {
  start: SerializedStyles
  end: SerializedStyles
  center: SerializedStyles
  spaceAround: SerializedStyles
  spaceBetween: SerializedStyles
  fill: SerializedStyles
  fillEvenly: SerializedStyles
}

const distributionStyles: IDistributionStyles = {
  start: css`
    justify-content: flex-start;
  `,
  end: css`
    justify-content: flex-end;
  `,
  center: css`
    justify-content: center;
  `,
  spaceAround: css`
    justify-content: space-around;
  `,
  spaceBetween: css`
    justify-content: space-between;
  `,
  fill: css``,
  fillEvenly: css``
}

// ITEM STYLES
const distributionFillStyles = css`
  flex: 1 1 auto;
`
const distributionFillEvenlyStyles = css`
  flex: 1 0 0%;
  min-width: fit-content;
`

const itemFillStyles = css`
  flex: 1 1 auto;
`

// FULL WIDTH AT STYLES
interface IFullWidthAtStyles {
  all: SerializedStyles
  mobileOnly: SerializedStyles
  tabletOrLess: SerializedStyles
  desktopOrLess: SerializedStyles
}

const fullWidthAtStyles: IFullWidthAtStyles = {
  all: css`
    flex: 1 1 100%;
  `,
  mobileOnly: css`
    @media ${MOBILE} {
      flex: 1 1 100%;
    }
  `,
  tabletOrLess: css`
    @media ${TABLET_OR_LESS} {
      flex: 1 1 100%;
    }
  `,
  desktopOrLess: css`
    @media ${DESKTOP_OR_LESS} {
      flex: 1 1 100%;
    }
  `
}

type Spacing = 'none' | 'superTight' | 'veryTight' | 'tight' | 'relaxed' | 'veryRelaxed'
type Alignment = 'start' | 'end' | 'center' | 'stretch' | 'baseline'
type BreakPoints = 'all' | 'mobileOnly' | 'tabletOrLess' | 'desktopOrLess'

type Distribution =
  | 'start'
  | 'end'
  | 'center'
  | 'spaceAround'
  | 'spaceBetween'
  | 'fill'
  | 'fillEvenly'

export interface SpreaderChildProps {
  children?: any
  distribution?: Distribution
  fullWidthAt?: BreakPoints
  spacing?: Spacing
  fill?: boolean
}

export const Item: React.SFC<SpreaderChildProps> = ({
  children,
  distribution,
  fullWidthAt,
  spacing,
  fill,
  ...rest
}: SpreaderChildProps) => {
  return (
    <div
      css={[
        spacing && itemSpacingStyles[spacing],
        distribution === 'fill' && distributionFillStyles,
        distribution === 'fillEvenly' && distributionFillEvenlyStyles,
        fullWidthAt && fullWidthAtStyles[fullWidthAt],
        fill && itemFillStyles
      ]}
      {...rest}
    >
      {children}
    </div>
  )
}

export interface ISpreaderProps {
  children: React.ReactNode
  spacing?: Spacing
  alignment?: Alignment
  distribution?: Distribution
  wrap?: BreakPoints
  vertical?: boolean
}

class Spreader extends React.Component<ISpreaderProps, never> {
  static Item = Item
  render() {
    const {
      children = [],
      spacing = 'tight',
      alignment = 'stretch',
      distribution,
      wrap,
      vertical,
      ...rest
    } = this.props

    // Pass extra props to the children
    const childrenWithExtraProp = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          // @ts-ignore
          spacing: spacing,
          distribution: distribution
        })
      }
      return child
    })

    return (
      <div
        css={[
          spreaderBaseStyles,
          vertical && verticalStyles,
          wrap && wrapStyles[wrap],
          spacing && spacingStyles[spacing],
          alignment && alignmentStyles[alignment],
          distribution && distributionStyles[distribution]
        ]}
        {...rest}
      >
        {childrenWithExtraProp}
      </div>
    )
  }
}

Item.defaultProps = {
  children: null
}

export default Spreader
