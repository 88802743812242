import React from 'react'
import Icon from '~/components/common/Icon'
import { css } from '@emotion/core'

export const SuccessNotice: React.FC = ({ children }) => {
  return (
    <div
      css={css`
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
      `}
    >
      <Icon
        color="green"
        circular
        icon="checkmark"
        size="huge"
        css={css`
          flex-shrink: 0;
        `}
      />

      <div
        css={css`
          margin-left: 20px;
        `}
      >
        {children ? children : 'Success'}
      </div>
    </div>
  )
}

export const ErrorNotice: React.FC = ({ children, ...rest }) => {
  return (
    <div
      css={css`
        text-align: left;
        width: 100%;
        display: flex;
        align-items: center;
      `}
      {...rest}
    >
      <Icon
        icon="block"
        size="big"
        circular
        color="yellow"
        css={css`
          flex-shrink: 0;
        `}
      />

      <div
        css={css`
          margin-left: 20px;
        `}
      >
        {children ? children : 'Error'}
      </div>
    </div>
  )
}
